import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import LoginPage from './pages/LoginPage/LoginPage';
import AppHeader from './pages/AppHeader';
import UnauthorizedAccess from './pages/UnauthorizedAccess';
import OTPpage from './pages/OTPpage';
import TimeTicker from './pages/TimeTicker';
import ScrollToTop from './pages/components/ScrollToTop';
import Consent from './pages/PrivacyNotes';
import "./common/styles/appbar.css";
import "./common/styles/common.css";
import "./common/styles/drawer.css";
import "./common/styles/component.css";
import "./common/styles/mobile.css";
import "./common/styles/fonts.css";
import HiringForm from './pages/HiringForm';
import HiringFormStatic from './pages/HiringForm_Static';

import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from "@auth0/auth0-react";
import IdleTimer from 'react-idle-timer';
import { saveAccessTokenAction, storeUserDetailsAction } from './Redux/Actions/actions';

export default function AppNew() {
    // const auth = useAuth();
    const dispatch = useDispatch();
    // const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );

    const [isLoader, setIsLoader] = useState(false);

    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const allDetails = useAuth0();

    async function fetchAccessToken() {
        try {
            const token = await getAccessTokenSilently();
            // this.setState({ accessToken: token });
            // this.saveStateToLocalStorage();
            dispatch(saveAccessTokenAction(token));
            console.log('Access Token:', token);
            // const result = await this.validateMailWithToken(token);
            return token;
        } catch (error) {
            console.error('Error fetching access token:', error);
        }
    };

    async function validateMailWithToken(generatedToken) {
        const data = new FormData();
        data.append('Token', generatedToken);
        const options = {
            method: 'post',
            body: data,
        }
        await fetch('Login/IsRegistered', options)
            .then(response => response.json())
            .then(async data => {
                console.log("validate api", data);
                dispatch(storeUserDetailsAction(data?.result));
                return await data;
            }).catch(error => {
                console.log("Error:", error)
            });
    }

    useEffect(async () => {
        if (isAuthenticated && user) {
            console.log("userdetails", allDetails);
            console.log(user);

            const token = await fetchAccessToken();
            let validateToken = await validateMailWithToken(token);
            /* getUserMetadata();*/
            setIsLoader(true);

        }
    }, [isLoading, isAuthenticated, getAccessTokenSilently, user?.sub]);


    function sessionExpire(auth, dispatch) {
        var newurl =
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname;
        window.history.pushState({ path: newurl }, "", `/`);
        localStorage.clear();
        sessionStorage.clear();
        if (auth !== undefined) {
            // dispatch(storeUserDetailsAction(""));

            caches.keys().then((names) => {
                names.forEach((name) => {
                    caches.delete(name);
                });
            });
        }
        window.location.href = "/";
    }


    var routesLogin = (
        <Switch>
            <Route exact path="/" render={() =>
                <LoginPage
                />
            }
            />
            <Route exact path="/LoginPage" render={() =>
                <LoginPage
                />
            }
            />
            <Redirect from="/" to="/LoginPage" />
        </Switch>
    );

    var routesPrivacy = (
        <Switch>
            <Route exact path="/Consent"
                render={() =>
                    <Consent
                        url={"http://localhost:54008"}
                    />
                }
            />
            <Redirect from="/" to="/Consent" />
            <Route exact path="/CandidateData" render={(props) =>
                <HiringForm
                    url={"http://localhost:54008/CandidateData"}
                />
            } />
            <Route exact path="/HiringFormStatic" render={() =>
                <HiringFormStatic
                    url={"http://localhost:54008/CandidateData"}
                />
            } />
        </Switch>
    );

    return (
        // <BrowserRouter>
        //     <div>
        //         {appHeader}
        //         <main className="">{routesPrivacy}</main>
        //     </div>
        // </BrowserRouter>

        <BrowserRouter>

            <div>
                {isAuthenticated && userDetails?.mail !== '' ? !userDetails?.isAlreadyRegistered ? (
                    <div className="amps-master-block">
                        <AppHeader
                            loginDetails={userDetails}
                        />
                        <main className="">{routesPrivacy}</main>
                    </div>
                ) : <main className="">{routesLogin}</main> :
                    <main className="">{routesLogin}</main>
                }
            </div>

            <div>
                <IdleTimer
                    timeout={1000 * 60 * 20}
                    onIdle={sessionExpire}
                    debounce={250}
                />
                {/* {isLoader === true ? <Loading /> : <></>} */}
            </div>
        </BrowserRouter>

    );
}
import React from "react";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from '@material-ui/core/Tooltip';
import SwipeableDrawerSection from "./SwipeDrawer";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Box from '@material-ui/core/Box';
import { useAuth0 } from "@auth0/auth0-react";

export default function AppHeader(props) {
    const anchor = "left";
    //let pageURL = {
    //    url1: "http://localhost:5000/",
    //    url2: "http://localhost:5000/ForgetPassword",
    //    url3: "http://localhost:5000/OTPPage"
    //};
    let defaultUrl = window.location.href;
    var arrURL = defaultUrl.split('/');
    let pageURLPart = {
        part1: "ForgetPassword",
        part2: "OTPPage",
        part3: "ResetPassword",
        part4: "UnauthorizedAccess"
    };
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
        </div>
    );

    const { logout } = useAuth0();
    function logoutUser() {
        localStorage.clear();
        sessionStorage.clear();
        logout({ logoutParams: { returnTo: window.location.origin } });
    }

    return (
        <React.Fragment>
            <AppBar position="fixed" className="no-print">
                <Toolbar className={"header-light-bg"}>
                    {
                        //defaultUrl === pageURL.url1 || defaultUrl === pageURL.url2 || defaultUrl === pageURL.url3
                        arrURL[3] === "" || arrURL[3] === pageURLPart.part1 || arrURL[3] === pageURLPart.part2 || (arrURL[3] === pageURLPart.part3) || arrURL[3] === pageURLPart.part4
                            ?
                            <React.Fragment> </React.Fragment>
                            :
                            <React.Fragment key={anchor}>
                                <SwipeableDrawerSection
                                    loginDetails={props.loginDetails}
                                    className="height-inherit bg-dark-blue"
                                    anchor={anchor}
                                    open={state[anchor]}
                                    onClose={toggleDrawer(anchor, false)}
                                    onOpen={toggleDrawer(anchor, true)}
                                    roleId={props.roleId}
                                >
                                    {list(anchor)}
                                </SwipeableDrawerSection>

                            </React.Fragment>
                    }

                    <div className={"d-flex  flex-wrap pt-8 jc-space-bt align-item-center width-100"}>
                        <a href="/" rel="">
                            <img src={"../assets/images/siemens-logo-white.svg"} alt="Siemens Company Logo" width="125" height="20" className="siemens-logo" />
                        </a>

                        <div className="d-flex">
                            <Box display={{ xs: 'none', sm: 'block' }}>
                                {
                                    //defaultUrl === pageURL.url1 || defaultUrl === pageURL.url2 || defaultUrl === pageURL.url3
                                    arrURL[3] === "" || arrURL[3] === pageURLPart.part1 || arrURL[3] === pageURLPart.part2 || (arrURL[3] === pageURLPart.part3) || arrURL[3] === pageURLPart.part4
                                        ?
                                        <React.Fragment> </React.Fragment>
                                        :
                                        <React.Fragment key={anchor}>
                                            <p className="m-0 clr-white cstLineHeight">Welcome</p>
                                        </React.Fragment>
                                }
                                {/* <a href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" target="_blank" rel="noopener noreferrer">
                                    <Tooltip title="Help">
                                        <IconButton aria-label="Help">
                                            <HelpOutlineOutlinedIcon style={{ color: "#2EE6E2" }} className="appbar-icon" />
                                        </IconButton>
                                    </Tooltip>
                                </a>*/}


                            </Box>

                            {
                                !arrURL[3] === pageURLPart.part4
                                    ?
                                    <React.Fragment> </React.Fragment>
                                    :
                                    (
                                        (arrURL[3] === pageURLPart.part3)
                                            ?
                                            <React.Fragment> </React.Fragment>
                                            :
                                            <React.Fragment>
                                                {/* <a onClick={logout}> */}
                                                <a>
                                                    <Tooltip title="Logout" onClick={logoutUser}>
                                                        <IconButton aria-label="Logout">
                                                            <span>
                                                                {/*<ExitToAppIcon style={{ color: "#2EE6E2" }} className="appbar-icon" />*/}
                                                                {<PowerSettingsNewIcon style={{ color: "#2EE6E2" }} className="appbar-icon" />}
                                                            </span>
                                                        </IconButton>
                                                    </Tooltip>
                                                </a>
                                            </React.Fragment>
                                    )
                            }
                        </div>
                    </div>
                </Toolbar>
                <div className="d-flex my-0 pl-1 bg-dark-blue jc-start">
                    <Box display={{ xs: 'block', sm: 'none' }}>
                        {
                            //defaultUrl === pageURL.url1 || defaultUrl === pageURL.url2 || defaultUrl === pageURL.url3
                            arrURL[3] === "" || arrURL[3] === pageURLPart.part1 || arrURL[3] === pageURLPart.part2 || arrURL[3] === pageURLPart.part4
                                ?
                                <React.Fragment> </React.Fragment>
                                :
                                <React.Fragment key={anchor}>
                                    <p className="clr-text-header mr-1 my-0">Welcome </p>
                                </React.Fragment>
                        }
                    </Box>
                </div>
            </AppBar>

        </React.Fragment>
    );
}
